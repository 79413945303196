export * from './registration'
export * from './times'
export const HASHCODE =
  'JGFyZ29uMmlkJHY9MTkkbT02NTUzNix0PTIscD0xJFQ0QW82T00rdzBxUzU1SnBpZ1owVEEkM2k0WU5JM255RlB0dS9sY1ZjWXhlazArZ0l6N050cmxnbk8wNGs5alhIUQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA='
export * from './faq'

export const RUNDOWN = [
  {
    startTime: new Date('2022-11-12 08:00'),
    endTime: new Date('2022-11-12 19:30'),
    textRightTitle: 'COMPFEST Playground',
    textRightSubtitle: 'Open Gate and Registration',
    isPg: true,
  },
  {
    startTime: new Date('2022-11-12 09:00'),
    endTime: new Date('2022-11-12 09:30'),
    textRightTitle: 'Main Event Opening',
    isPg: true,
  },
  {
    startTime: new Date('2022-11-12 09:00'),
    endTime: new Date('2022-11-12 10:30'),
    textRightTitle: 'COMPFEST Talks',
    textRightSubtitle: 'Re-registration COMPFEST Talks 1',
  },
  {
    startTime: new Date('2022-11-12 10:30'),
    endTime: new Date('2022-11-12 12:00'),
    textRightTitle: 'COMPFEST Talks (Seminar)',
    textRightSubtitle: 'The Significance of Artificial Intelligence in Digital Marketing',
    i: 1,
  },
  {
    startTime: new Date('2022-11-12 12:00'),
    endTime: new Date('2022-11-12 12:45'),
    textRightTitle: 'ISHOMA',
  },
  {
    startTime: new Date('2022-11-12 12:10'),
    endTime: new Date('2022-11-12 12:45'),
    textRightTitle: 'COMPFEST Talks',
    textRightSubtitle: 'Re-registration COMPFEST Talks 2',
  },
  {
    startTime: new Date('2022-11-12 12:45'),
    endTime: new Date('2022-11-12 14:00'),
    textRightTitle: 'COMPFEST Talks (Talkshow)',
    textRightSubtitle: `Self Improvement: Setting Boundaries to Achieve Work-life Balance`,
    i: 2,
  },
  {
    startTime: new Date('2022-11-12 14:00'),
    endTime: new Date('2022-11-12 14:30'),
    textRightTitle: 'COMPFEST Talks',
    textRightSubtitle: 'Re-registration COMPFEST Talks 3',
  },
  {
    startTime: new Date('2022-11-12 14:30'),
    endTime: new Date('2022-11-12 14:45'),
    textRightTitle: 'Band Performance',
    textRightSubtitle: 'Asciipela',
  },
  {
    startTime: new Date('2022-11-12 14:45'),
    endTime: new Date('2022-11-12 16:00'),
    textRightTitle: 'COMPFEST Talks (Seminar)',
    textRightSubtitle: 'The Rise and Fall of Digital Startup in Indonesia',
    i: 3,
  },
  {
    startTime: new Date('2022-11-12 16:00'),
    endTime: new Date('2022-11-12 16:30'),
    textRightTitle: 'COMPFEST Talks',
    textRightSubtitle: 'Re-registration COMPFEST Talks 4',
  },
  {
    startTime: new Date('2022-11-12 16:30'),
    endTime: new Date('2022-11-12 17:45'),
    textRightTitle: 'COMPFEST Talks (Talkshow)',
    textRightSubtitle:
      'The Art of Entertaining: Maintaining ideas of content creating through technology adaptations',
    i: 4,
  },
  {
    startTime: new Date('2022-11-12 17:45'),
    endTime: new Date('2022-11-12 18:30'),
    textRightTitle: 'ISHOMA',
  },
  {
    startTime: new Date('2022-11-12 18:30'),
    endTime: new Date('2022-11-12 18:45'),
    textRightTitle: 'Band Performance',
    textRightSubtitle: 'TBA',
  },
  {
    startTime: new Date('2022-11-12 18:45'),
    endTime: new Date('2022-11-12 19:15'),
    textRightTitle: 'TechTalks in Main Event',
  },
  {
    startTime: new Date('2022-11-12 19:15'),
    endTime: new Date('2022-11-12 19:30'),
    textRightTitle: 'Main Event Closing',
    isPg: true,
  },
]

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const getDateStringFormat = (date?: Date | null) => {
  if (!date) return ''
  const now = new Date()
  if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  )
    return 'Today'
  if (
    date.getDate() === now.getDate() + 1 &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  )
    return 'Tomorrow'
  if (
    date.getDate() === now.getDate() - 1 &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  )
    return 'Yesterday'
  return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`
}

export * from './tata-cara'
