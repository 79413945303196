import { FC, ReactNode } from 'react'
import styled from 'styled-components'

type BannerProps = {
  leftChild: ReactNode
  rightChild: ReactNode
  button?: ReactNode
  mode: 'left' | 'right'
  className?: string
}

const BannerContainer = styled.div`
  background: linear-gradient(106.48deg, #1c1c28 0%, #363649 100%);
  box-shadow: 0px 100px 126px rgba(0, 0, 0, 0.15), 0px 41.7776px 52.6398px rgba(0, 0, 0, 0.107828),
    0px 22.3363px 28.1437px rgba(0, 0, 0, 0.0894161), 0px 12.5216px 15.7772px rgba(0, 0, 0, 0.075),
    0px 6.6501px 8.37913px rgba(0, 0, 0, 0.0605839),
    0px 2.76726px 3.48674px rgba(0, 0, 0, 0.0421718);
  border-radius: 24px;
`

export const Banner: FC<BannerProps> = ({
  leftChild,
  rightChild,
  button,
  mode,
  className = '',
}) => {
  return (
    <BannerContainer className={`py-10 px-5 ${className}`}>
      <div
        className={`flex ${
          mode === 'left' ? 'flex-col-reverse' : 'flex-col'
        } items-center justify-center lg:flex-row lg:justify-between`}
      >
        {leftChild}
        {rightChild}
      </div>
      {button && <div className="mt-8 flex justify-center md:mt-10">{button}</div>}
    </BannerContainer>
  )
}
