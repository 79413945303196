export type CFT = {
  title: string
  description: string
  speakers: {
    name: string
    title: string
    image?: string
  }[]
  moderator: {
    name: string
    title: string
    image?: string
    publish: boolean
  }
  tags: string[]
  startTime: Date
  endTime: Date
  time: string
  peopleImg?: string
  id: string
}

export const CF_TALKS: CFT[] = [
  {
    title: 'The Significance of Artificial Intelligence in Digital Marketing',
    description:
      'The Significance of Artificial Intelligence in Digital Marketing merupakan seminar yang membahas pengetahuan mengenai teknologi Artificial Intelligence. Teknologi Artificial Intelligence memiliki jenis pemanfaatan yang sangat banyak dan salah satu nya yaitu pemanfaatan Artificial Intelligence dalam bidang digital marketing. Sehingga pada seminar ini kami mengundang para pembicara expert dalam Teknologi AI dan Digital Marketing untuk membahas Artificial  Intelligence serta pemanfaatannya dalam bidang Digital Marketing yang akan berfokus kepada seberapa besar impact yang dihasilkan pemanfaatan Artificial Intelligence dalam digital marketing.',
    speakers: [
      {
        name: 'Syafri Bahar',
        title: 'VP of Data Science',
        image: '/me/sp-syafri-ct1.jpeg',
      },
      {
        name: 'Dwi Prasanto Wicaksono',
        title: 'Co-Founder | Commercial of Balesin.id',
        image: '/me/sp-dwi-ct1.png',
      },
    ],
    moderator: {
      name: 'Tara Mecca Luna',
      title: 'Product Manager at Pluang',
      image: '/me/mod-ct1.jpg',
      publish: true,
    },
    startTime: new Date('2022-11-12 10:30'),
    endTime: new Date('2022-11-12 12:00'),
    tags: ['Digital Marketing', 'Artificial Intelligence', 'Startup', 'Technology'],
    time: '10.30 - 12.00 WIB',
    peopleImg: '/me/people-ct1.png',
    id: '67f620e4-86b4-4b2b-adad-6a15226ab59f',
  },
  {
    title: 'Self Improvement: Setting Boundaries to Achieve Work-life Balance',
    description:
      'Setting Boundaries to Achieve Work-life Balance merupakan talkshow yang dilatarbelakangi dengan maraknya fenomena hustle-culture sekaligus mayoritas milenium yang merasa pekerjaan dan gaya hidup mereka tidak memiliki keseimbangan yang baik. Talkshow ini akan memberi insight serta penjelasan yang menarik sebagai pendorong dalam self improvement dengan mengedukasi para audiens mengenai pentingnya memiliki personal boundaries serta cara mencapai work-life balance.',
    speakers: [
      {
        name: 'Sheryl Sheinafia',
        title: 'Singer, Song Writer, Actor',
        image: '/me/sheryl.png',
      },
    ],
    moderator: {
      name: 'Danang Suryonegoro',
      title: 'Pelaku Seni',
      image: '/me/danang.png',
      publish: true,
    },
    tags: ['Self-improvement', 'Work-life balance', 'Hustle culture', 'Setting boundaries'],
    startTime: new Date('2022-11-12 12:45'),
    endTime: new Date('2022-11-12 14:00'),
    time: '12.45 - 14.00 WIB',
    peopleImg: '/me/people-ct3.png',
    id: '13e3e907-a014-41b2-84fe-a1cdacb58fd0',
  },
  {
    title: 'The Rise and Fall of Digital Startup in Indonesia',
    description:
      'The Rise and Fall of Digital Startup in Indonesia merupakan talk show yang membahas mengenai perkembangan digital startup di Indonesia yang kesuksesannya banyak menginspirasi anak bangsa. Namun seiring berjalannya waktu, adapun beberapa perusahaan yang cepat melambung namun cepat pula hilang. Oleh karena itu di acara ini, kami mengundang para startup-expert yang akan membahas mengenai perkembangan digital startup di Indonesia, serta tragedi kegagalan dan kesuksesan digital startup di Indonesia.',
    speakers: [
      {
        name: 'Alamanda Shantika',
        title: 'CEO & Founder Binar Academy',
        image: '/me/alamanda.png',
      },
      {
        name: 'Bianca Widjadja',
        title: 'Investments, BRI Ventures',
        image: '/me/bianca.jpg',
      },
    ],
    moderator: {
      name: 'Gabrielle Christie',
      title: 'Product Marketing, Bibit',
      image: '/me/gabrielle.jpg',
      publish: true,
    },
    tags: ['Startup', 'Ventures', 'Venture capital', 'Business', 'Bubble burst'],
    startTime: new Date('2022-11-12 14:45'),
    endTime: new Date('2022-11-12 16:00'),
    time: '14.45 - 16.00 WIB',
    peopleImg: '/me/people-ct2.png',
    id: '7ea8af87-2cc1-4b5c-b01f-157e919b3c64',
  },
  {
    title:
      'The Art of Entertaining: Maintaining Ideas of Content Creating Through Technology Adaptations',
    description:
      'The Art of Entertaining: Maintaining ideas of content creating through technology adaptations merupakan talkshow yang menggali pandangan seorang content creator dalam drastisnya perubahan industri entertainment terutama pada platform-platform bebas seperti Youtube, Instagram, Tiktok, dll. Perubahan dunia entertainment diiringi dengan pesatnya perkembangan teknologi menuntut content creator sebagai entertainer untuk tidak hanya memunculkan ide-ide kreatif, tetapi juga cermat dalam melihat perubahan lingkungan teknologi agar dapat bertahan di industri saat ini.',
    speakers: [
      {
        name: 'Bayu Skak',
        title: 'Content Creator',
        image: '/me/bayu.png',
      },
    ],
    moderator: {
      name: 'Leonardo Edwin',
      title: 'Content Creator',
      image: '/me/leonardo.jpg',
      publish: true,
    },
    tags: [],
    startTime: new Date('2022-11-12 16:30'),
    endTime: new Date('2022-11-12 17:45'),
    time: '16.30 - 17.45 WIB',
    peopleImg: '/me/people-ct4.png',
    id: 'e606a8b9-7561-49a9-8849-dc18d36cce00',
  },
]

export const CT_TAGS = [
  'Startup',
  'Ventures',
  'Venture capital',
  'Business',
  'Bubble burst',
  'Self-improvement',
  'Work-life balance',
  'Hustle culture',
  'Setting boundaries',
  'Digital Marketing',
  'Artificial Intelligence',
  'Technology',
]
