import { CFT, CF_TALKS, CT_TAGS } from 'app/main-event/constants/cf-talks'
import { Image, useQuery } from 'blitz'
import { FC, useState } from 'react'
import { Button } from 'silicon-design-system'
import { Banner } from './Banner'
import Close from 'public/me/close.svg'
import getRundown from 'app/main-event/queries/me/getRundown'

type SpeakerCardProps = {
  image?: string
  name: string
  title: string
  cardClass?: string
}

const SpeakerCard: FC<SpeakerCardProps> = ({ image, name, title, cardClass = '' }) => {
  return (
    <div className={`h-fit w-fit rounded-3xl p-6 ${cardClass}`}>
      <div className="relative h-[200px] w-[200px] overflow-hidden rounded-xl">
        {image && <Image src={image} layout="fill" alt={name} />}
        {!image && (
          <div className="flex h-full flex-col justify-center">
            <span className="text-base font-bold">More Speakers to Come!</span>
            <span className="text-sm font-semibold text-white2">
              Visit our social media to get the latest update
            </span>
          </div>
        )}
      </div>

      <div className="relative z-5 -mt-10 flex w-[200px] justify-end">
        <div className="min-w-[200px] rounded-xl bg-black4 py-2 px-4 text-right font-rflex font-bold">
          <span className="atlantic-acad text-base">{name}</span>
          <br />
          <span className="text-xs text-white">{title}</span>
        </div>
      </div>
    </div>
  )
}

export const CfTalks: FC = () => {
  const [show, setShow] = useState(false)
  const colors = ['bg-bananaLight15', 'bg-blueberryLight15', 'bg-grapeLight15']
  const [cft, setCft] = useState<undefined | CFT>(undefined)
  const [showModal, setShowModal] = useState(false)
  const [data] = useQuery(getRundown, null, {
    suspense: false,
  })

  return (
    <section>
      <Banner
        leftChild={
          <div className="max-w-[600px] text-center lg:max-w-[600px] lg:text-left">
            <div className="flex items-center justify-center lg:justify-start">
              <div className="relative h-[35px] w-[220px] md:h-[41px] md:w-[254px]">
                <Image src="/assets/cf.svg" alt="compfest" layout="fill" />
              </div>
              <h3 className="ml-4 font-rflex text-xl font-bold md:text-4xl lg:text-5xl">TALKS</h3>
            </div>
            <p className="mt-2 font-rflex text-xl font-bold text-grapeLight md:text-4xl lg:text-5xl">
              Learn Tech Knowledge
            </p>
            <p className="mt-8 font-sans text-sm font-semibold vsm:text-base md:mt-10">
              Dapatkan wawasan baru dari pembicara dan bintang tamu ternama dan kompeten di
              bidangnya!
            </p>
          </div>
        }
        rightChild={
          <div className="relative mx-auto mb-8 h-[195px] w-[250px] smm:h-[235px] smm:w-[300px] md:mb-10 md:h-[345px] md:w-[440px] lg:mx-0 lg:mb-0">
            <Image src="/images/cftalks-mock.png" alt="CF Talks" layout="fill" />
          </div>
        }
        button={
          <Button
            text={`${show ? 'Tutup' : 'Lihat'} Semua Topik`}
            onClick={() => setShow((prev) => !prev)}
          />
        }
        mode="left"
      />

      <div
        className={`${
          show ? 'block' : 'hidden'
        } mt-5 box-border max-w-full overflow-x-auto overflow-y-hidden rounded-3xl bg-section px-4 py-6 text-center sm:px-8 sm:py-10 lg:py-12 lg:px-10`}
      >
        <h2 className="font-rflex text-xl font-bold md:text-4xl lg:text-5xl">
          Speakers and Topics
        </h2>
        <p className="mt-3 font-semibold text-milk vsm:text-base">
          Ikuti acara bincang-bincang virtual tentang topik tertentu yang terkait dengan teknologi!
        </p>
        <div className="my-6 flex items-start space-x-2 overflow-scroll sm:my-10">
          {CT_TAGS.map((topic) => (
            <div
              key={topic}
              className="h-fit min-w-fit rounded-2xl border-2 border-white2 py-2 px-3 font-sans text-base font-semibold text-white2"
            >
              <span>{topic}</span>
            </div>
          ))}
        </div>

        <div className="flex flex-col space-y-4">
          {/* SPEAKERS */}
          {CF_TALKS.map((cft, i) => {
            const cfDb = data?.find(
              (d) => d.textRightSubtitle.toLowerCase() === cft.title.toLowerCase(),
            )
            const id = cfDb ? cfDb.id : ''
            return (
              <div
                className="flex flex-col rounded-3xl bg-cardSection p-6 lg:flex-row"
                key={cft.title + i}
              >
                <div className="w-full  text-center lg:w-72 lg:text-left">
                  <h3 className="font-rflex text-2xl font-bold">{cft.title}</h3>
                  <div className="my-4 font-sans text-xs font-bold">
                    <span className="atlantic-acad">Sabtu, 12 November 2022</span>
                    <br />
                    <span>{cft.time}</span>
                  </div>
                  <div className="flex flex-col items-center space-y-3 lg:items-start">
                    <Button
                      text="Daftar"
                      onClick={() => (window.location.href = `/registration?ctId=${id}`)}
                      variant="secondary"
                      bg="rgba(255, 255, 255, 0.1)"
                      disabled={cfDb ? cfDb.type !== 'regular' && cfDb.type !== 'limited' : false}
                      w={250}
                    />
                    <Button
                      text="Lihat Detail"
                      onClick={() => {
                        setCft(cft)
                        setShowModal(true)
                      }}
                      w={250}
                    />
                  </div>
                </div>

                <div className="mt-5 flex flex-col items-center justify-center gap-2 space-y-4 sm:flex-row sm:flex-wrap sm:items-start sm:space-y-0 lg:mt-0 lg:ml-10 lg:flex-nowrap lg:space-x-4">
                  {cft.speakers.map(({ name, title, image }, i) => (
                    <SpeakerCard
                      key={name}
                      name={name}
                      title={title}
                      image={image}
                      cardClass={colors[i % 3]}
                    />
                  ))}
                  {cft.moderator.publish && (
                    <SpeakerCard {...cft.moderator} cardClass={colors[2]} />
                  )}
                </div>
              </div>
            )
          })}
        </div>

        {/* SPEAKERS */}
        <div className="mx-auto mt-5 w-fit text-sm md:mt-10">
          <Button
            text="Daftar COMPFEST Talk"
            onClick={() => (window.location.href = '/registration')}
          />
        </div>
      </div>
      {show && (
        <CFTModal
          show={showModal}
          onClose={() => {
            setShowModal(false)
            setCft(undefined)
          }}
          cft={cft}
        />
      )}
    </section>
  )
}

type CFTModalProps = {
  show: boolean
  onClose: () => unknown
  cft?: CFT
}

export const CFTModal: FC<CFTModalProps> = ({ onClose, cft, show }) => {
  if (!show) return null
  return (
    <div className="fixed inset-0 z-100 flex items-center justify-center">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-50 mx-4 h-[95vh] w-fit max-w-[1216px] overflow-y-auto overflow-x-hidden rounded-3xl bg-matteBlack p-6 lg:h-auto lg:w-full xl:p-20">
        <div className="absolute top-0 left-0 -z-5 h-full w-full bg-cftJar"></div>
        <div className="z-5 flex w-full justify-end">
          <Close className="w-fit cursor-pointer" onClick={() => onClose()} />
        </div>
        <div className="mt-4 lg:flex lg:space-x-8">
          {/* LEFT */}
          <div className="mb-4 lg:max-w-[60%]">
            {cft?.peopleImg && (
              <div className="block lg:hidden">
                <Image src={cft.peopleImg} alt="Speakers" width={350} height={280} />
              </div>
            )}
            <h2 className="atlantic-acad font-rflex text-2xl font-bold">COMPFEST Talks</h2>
            <div className="mt-4 max-w-[495px]">
              <h3 className="font-rflex text-3xl font-bold">{cft?.title}</h3>
            </div>
            <div className="my-8 font-rflex text-xl font-bold">
              <a
                href="https://www.google.com/maps?cid=3392522267984497873"
                target="_blank"
                rel="noreferrer"
                className="text-blueberryLight"
              >
                Balairung Universitas Indonesia
              </a>
              <br />
              <span>Sabtu, 12 November 2022</span>
            </div>
            <Button
              text="Daftar Sekarang"
              onClick={() => {
                window.location.href = `/registration?ctId=${cft?.id}`
              }}
            />
            <div className="mt-8 max-w-[380px] font-rflex text-base font-bold lg:max-w-none">
              <span className="text-bananaDark">Deskripsi Acara</span>
              <br />
              <p>{cft?.description}</p>
            </div>
          </div>
          {/* LEFT */}

          {/* RIGHT */}
          <div className="lg:max-w-[350px]">
            {cft?.peopleImg && (
              <div className="hidden lg:block">
                <Image src={cft.peopleImg} alt="Speakers" width={350} height={280} />
              </div>
            )}
            <div className="mt-8 font-rflex text-base font-bold">
              <span className="text-bananaDark">Pembicara</span>
              <br />
              <ul className="mt-2 flex flex-col space-y-2">
                {cft?.speakers.map(({ name, title, image }) => (
                  <li key={name}>
                    <span className="font-bold">{name}</span>
                    <br />
                    <span className="font-sans text-xs font-semibold text-white2">{title}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-8 font-rflex text-base font-bold">
              <span className="text-bananaDark">Moderator</span>
              <br />
              <span className="font-bold">{cft?.moderator.name}</span>
              <br />
              <span className="font-sans text-xs font-semibold text-white2">
                {cft?.moderator.title}
              </span>
            </div>
          </div>
          {/* RIGHT */}
        </div>
      </div>
    </div>
  )
}
