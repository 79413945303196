export const ME_FAQ = [
  {
    question: 'Jam berapa saya harus hadir?',
    answer:
      'Pastinya secepat-cepatnya kamu harus segera datang untuk memeriahkan acara COMPFEST karena banyak banget hal menarik yang bisa kamu dapetin disini! COMPFEST akan buka pukul 08.00 sampai pukul 19.30. Jangan lupa dicatat ya!',
  },
  {
    question: 'Apa saja rangkaian acara yang akan diadakan pada Main Event?',
    answer:
      'Banyak sekali rangkaian acara seru yang sudah menunggu! Untuk kamu yang ingin memperluas pengetahuan dapat berpartisipasi dalam berbagai topik menarik yang dihadirkan dalam acara COMPFEST talks. Lalu, untuk kamu yang ingin mencari lowongan kerja atau ingin tahu mengenai berbagai perusahaan dapat mengunjungi booth-booth pada acara Job and Intern Fair. Selain itu, kamu juga bisa seru-seruan bareng di Playground sambil menikmati berbagai performance musik.',
  },
  {
    question:
      'Apabila Aku ingin keluar dari venue sebelum acara berakhir, bolehkah Aku kembali masuk ke dalam venue? ',
    answer:
      'Ya, tentu saja! kamu bisa masuk lagi ke dalam venue melewati pintu awal kamu masuk sebelumnya. Jadi kamu harus antri lagi ya!',
  },
  {
    question: 'Apakah Aku harus mengeluarkan biaya untuk menghadiri acara Main Event?',
    answer:
      'Tentu saja tidak! Kamu dapat menikmati seluruh rangkaian Main Event COMPFEST secara gratis. Kecuali kamu ingin membeli berbagai barang pada bazaar yang ada.',
  },
  {
    question: 'Apakah Aku bisa membawa makanan dan minuman dari luar?',
    answer:
      'Sayang sekali, kamu tidak boleh membawa makanan dan minuman dari luar. Tetapi tenang aja! Kami sudah menyediakan berbagai tenant di bazaar FnB yang bisa kamu pilih! ',
  },
  {
    question: 'Apa yang harus Aku bawa untuk menukarkan tiket dengan gelang?',
    answer:
      'Kamu bisa menunjukkan QR Code dari email-mu dan langsung menukarkannya pada pintu masuk, Selanjutnya kamu bisa langsung masuk ke venue dan bersenang-senang di dalam! Jaga gelangmu dan jangan sampai hilang yaa!',
  },
  {
    question: 'Apakah Aku boleh membawa hewan peliharaan?',
    answer:
      'Sayangnya tidak, kami sangat menghargai kenyamanan pengunjung lain yang sedang menjelajah main event COMPFEST.',
  },
  {
    question: 'Bolehkah aku membawa kamera masuk ke dalam venue?',
    answer:
      'Yup! Kamu boleh membawa kamera untuk mengabadikan seluruh momen kamu selama berada di acara Main Event COMPFEST.',
  },
  {
    question: 'Apabila Aku menggunakan kendaraan pribadi, dimana Aku bisa memarkirkan kendaraanya?',
    answer:
      'Kamu bisa memarkirkan kendaraanmu di wilayah parkir venue yaitu Balairung UI. Selain itu, kami juga menyediakan tim keamanan untuk memberikan anda keamanan, serta kenyamanan yang terbaik.',
  },
  {
    question: 'Haruskah anak-anak ditemani oleh orang dewasa?',
    answer:
      'Akan lebih baik apabila anak-anak dapat didampingi oleh orang dewasa. Tapi, jangan khawatir karena COMPFEST menyediakan berbagai event menarik dan dapat dinikmati berbagai kalangan usia!',
  },
  {
    question: `Apakah ada barang yang dilarang untuk dibawa ke venue?`,
    answer:
      'Tentu saja Compfest mengutamakan keamanan dan keamanan pengunjung, pengunjung dilarang membawa makanan maupun minuman, rokok/vape, korek api, senjata tajam, dan binatang peliharaan.',
  },
  {
    question: `Apakah Aku bisa membeli merchandise di venue?`,
    answer:
      'Yes, of course!, kamu bisa membeli merch COMPFEST melalui [Link Supermarket] dan mengambilnya di supermarket. Selain itu, kalau kamu ngumpulin poin di Playground, kamu bisa tukar poinnya dengan berbagai hadiah yang menarik!',
  },
  {
    question: `Apakah setiap topik COMPFEST Talks memiliki tiket yang berbeda?`,
    answer:
      'Benar! Kamu dapat mendaftarkan diri pada setiap topik COMPFEST Talks yang kamu inginkan. Jadi, jangan lupa untuk daftarkan dirimu ya!',
  },
  {
    question: `Apa saja benefit yang akan Aku dapatkan dengan mengikuti Main Event?`,
    answer:
      'Dengan berpartisipasi dalam rangkaian COMPFEST Talks, kamu bisa mendapatkan e-sertifikat untuk masing-masing COMPFEST Talks. Kamu juga bisa mendapatkan merchandise gratis bila beruntung!',
  },
  {
    question: `Aku sudah memiliki tiket, dimana aku bisa menonton COMPFEST Talks?`,
    answer:
      'Kamu bisa menonton COMPFEST Talks pada main stage yang ada di tengah Balairung ya! Kamu bisa melakukan re-registrasi dahulu sebelum masuk seating area.',
  },
  {
    question: `Aku kehabisan tiket online, apakah Aku masih bisa menonton COMPFEST Talks?`,
    answer:
      'Jangan khawatir! Kamu masih bisa menonton COMPFEST Talks dengan tiket On The Spot yang bisa didapati pada booth registrasi di samping area main stage. Namun, apabila seating area penuh, Kamu masih bisa menonton acara COMPFEST Talks dari luar seating area.',
  },
]
