export const TATA_CARA = {
  CF_TALK_RULES: [
    'Kunjungi exhibitor yang ada pada Main Event COMPFEST 14',
    'Selesaikan challenge yang ada pada exhibitor tersebut',
    'Akses QR peserta yang telah dicantumkan melalui email',
    'Penjaga exhibitor akan melakukan scan QR milik peserta',
    'Apabila scanning berhasil dilakukan oleh penjaga, maka point user akan bertambah',
  ],
  CLAIM_PRIZE_RULES: [
    'Kumpulkan poin dari berbagai challenge pada exhibitor',
    <>
      Kunjungin link{' '}
      <a
        href="https://vaksinln.dto.kemkes.go.id/"
        className="break-all text-blueberry"
        rel="noreferrer"
        target="_blank"
      >
        https://compfest.link/WebsiteSupermarket
      </a>
    </>,
    'Gunakan poinmu untuk menukar barang yang tersedia pada supermarket COMPFEST!',
  ],
  USE_POINT_RULES: [
    <>
      Daftarkan diri Anda pada topik COMPFEST Talks yang disukai pada website{' '}
      <a
        href="https://compfest.id/"
        className="break-all text-blueberry"
        rel="noreferrer"
        target="_blank"
      >
        compfest.id
      </a>
    </>,
    'Melakukan registrasi ulang pada booth registrasi di samping area main stage',
    'Memasuki seating area dan menonton COMPFEST Talks pada main stage yang terletak di tengah Balairung',
  ],
}
