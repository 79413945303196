export const KETENTUAN_REGISTRASI = [
  <>
    Hanya mereka yang telah menerima Vaksin COVID-19 dosis utama lengkap seperti yang ditunjukkan
    oleh akun Anda di aplikasi PeduliLindungi yang memenuhi syarat untuk membeli tiket acara.
  </>,
  <>Anda harus berusia 12 tahun atau lebih untuk menghadiri acara tersebut.</>,
  <>
    Jika Anda warga negara asing, pastikan Anda telah memvalidasi vaksinasi dengan membuka{' '}
    <a
      href="https://vaksinln.dto.kemkes.go.id/"
      className="break-all text-blueberry"
      rel="noreferrer"
      target="_blank"
    >
      https://vaksinln.dto.kemkes.go.id/
    </a>{' '}
    dan kemudian mengunduh aplikasi PeduliLindungi.
  </>,
  //   <>
  //     Anda setuju dan terikat dengan{' '}
  //     <a
  //       href="https://vaksinln.dto.kemkes.go.id/"
  //       className="break-all text-blueberry"
  //       rel="noreferrer"
  //       target="_blank"
  //     >
  //       Kebijakan Tiket Acara
  //     </a>{' '}
  //     ini dengan membeli tiket acara.
  //   </>,
]
